import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Modal from "react-modal";
import ActivityFeed from "../activity/ActivityFeed";
import store from "../../redux/store";
import { CurrentUser } from "../../services/User";
import { Form, ServiceProvider, useGetFormsQuery } from "../../services/forms/FormService";
import { Location } from "../../models/Location";
import { ActivityLogTypes, SendSmsRequest, useSendSmsMutation } from "../../services/SmsService";
import { Patient } from "../../services/MediaPatient";
import { PatientWithMedias, useGetPatientsByMobileNumberQuery } from "../../services/PatientsService";
import moment from "moment";
import { useCreateFormAnswerMutation } from "../../services/forms/FormAnswersService";
import { PatientFormRequest, useCreatePatientFormRequestMutation } from "../../services/forms/PatientFormRequestService";

export interface RequestReferralParams {
  isOpen: boolean;
  closeModal: () => void;
  currentUser: CurrentUser;
  resetGetForms: () => void;
}

export const RequestForm: React.FC<RequestReferralParams> = (
  params: RequestReferralParams,
) => {
  const [{ pageIndex, pageSize }] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [selectedFormId, setSelectedFormId] = useState<string>("");
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const [buttonColor, setButtonColor] = useState<string>("blue");

  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<
    string | undefined
  >("");

  const [refreshActivityFeed, setRefreshActivityFeed] = useState<number>(0);

  const [sendSms] = useSendSmsMutation();
  const [createFormAnswer] = useCreateFormAnswerMutation();
  const [createPatientFormRequest] = useCreatePatientFormRequestMutation();
  const [patientFormRequest, setPatientFormRequest] = useState<PatientFormRequest | null>(null);

  store.subscribe(() => {
    const state = store.getState();

    setCurrentLocation(state.currentLocation);
    setCurrentUser(state.currentUser);
  });

  const { data } = useGetFormsQuery({
    itemsPerPage: pageSize,
    itemOffset: pageIndex * pageSize,
    query: null,
    locationIds: currentLocation?.id ? [currentLocation.id] : null,
  });

  const { data: patientsData } = useGetPatientsByMobileNumberQuery(mobilePhoneNumber);

  const currentForm = () => data?.data.find((form: Form) => form.id === selectedFormId);

  useEffect(() => {
    if (patientsData?.data?.length === 1) {
      handleSelectingPatient(patientsData.data[0].id);
    }
  }, [patientsData]);


  const message = () => {
    if(selectedPatient) {
      return `Hi ${selectedPatient.firstName}, at ${titleizeString(currentUser?.tenantSlug || "")}, we're committed to providing you with the best care possible. Please complete the ${currentForm()?.formType.name} form found at this link: ${formUrl()}. Thank you!`;
    }

    return `Hello from ${titleizeString(currentUser?.tenantSlug || "")}, to help us provide you with the best care possible, please take a moment to complete our ${currentForm()?.formType.name} form found at this link: ${formUrl()}. Thank you!`;
  };

  const formUrl = () => {
    const form = currentForm();
    
    if (patientFormRequest) {
      return `${process.env.REACT_APP_HOST_URL}/${currentUser?.tenantSlug}/form_request/${patientFormRequest.publicId}`;
    }

    if (form && form?.serviceProvider === ServiceProvider.ELVATI_FORMS) {
      return `${process.env.REACT_APP_HOST_URL}/${currentUser?.tenantSlug}/form_request/${form.id}`;
    }

    return form?.externalUrl;
  };

  function titleizeString(inputString: string): string {
    const titleizedWords = inputString.split("_").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return titleizedWords.join(" ");
  }

  const [isSending, setIsSending] = useState<boolean>(false);

  const [buttonText, setButtonText] = useState<string>("Send Text Message");
  

  const sendTextMessage = async () => {
    setIsSending(true);
    setButtonText("Sending text message");

    try {
      if (selectedPatient && selectedFormId) {
        const response = await createPatientFormRequest({
          body: { 
            formId: selectedFormId,
            patientId: selectedPatient.id
          },
          tenantId: currentUser?.tenantSlug || "",
        }).unwrap();

        if (response.data) {
          setPatientFormRequest(response.data);
        }
      }

      const textMessageParam: SendSmsRequest = {
        mobilePhoneNumber: mobilePhoneNumber || "",
        textMessageBody: message(),
        typeOf: ActivityLogTypes.SmsRequestForm
      };

      await sendSms(textMessageParam).unwrap();

      if(selectedPatient && selectedFormId && currentLocation) {
        await createFormAnswer({
          formId: selectedFormId,
          patientId: selectedPatient.id,
          locationId: currentLocation.id,
          answersData: null,
        }).unwrap();
      }

      params.resetGetForms();

      setRefreshActivityFeed((prev) => prev + 1);
      setIsSending(false);
      setButtonText("Message successfully sent");
      setButtonColor("green");
      setTimeout(() => {
        setButtonColor("blue");
        setButtonText("Send Text Message");
      }, 3000);
    } catch (error) {
      setIsSending(false);
      setButtonText("Failed to send text message");
      setButtonColor("red");
      setTimeout(() => {
        setButtonColor("blue");
        setButtonText("Send Text Message");
      }, 3000);
    } finally {
      setIsSending(false);
    }
  };

  const handleSelectingPatient = (patientId: string) => {
    const patient = patientsData?.data?.find((patient: Patient) => patient.id === patientId);

    setSelectedPatient(patient || null);
  };

  return (
    <Modal
      isOpen={params.isOpen}
      onRequestClose={params.closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="relative transform overflow-hidden text-left transition-all">
        <div className="grid grid-cols-1 sm:grid-cols-12">
          <div className="sm:col-span-8">
            <div className="p-8">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3
                  className="text-xl font-semibold leading-6 text-gray-900 pb-4"
                  id="modal-title"
                >
                  Request Form
                </h3>

                <select
                  id="formSelect"
                  className="bg-gray-100 border border-gray-300 text-sm rounded-lg block w-full p-2.5 cursor-pointer mb-4"
                  value={selectedFormId}
                  onChange={(e) => setSelectedFormId(e.target.value)}
                >
                  <option value="" className="text-lg" >Select a form</option>
                  {data?.data.map((form: Form) => (
                    <option key={form.id} value={form.id} className="text-lg cursor-pointer">
                      {form.formType.name}
                    </option>
                  ))}
                </select>

                {
                  selectedFormId && (
                    <div>
                      <label className="block pb-4">
                        <span className="block text-sm font-medium text-gray-700 pb-2">
                          Mobile phone number
                        </span>
                        <PhoneInput
                          defaultCountry="AU"
                          value={mobilePhoneNumber}
                          onChange={(value) => setMobilePhoneNumber(value)}
                          className="w-full border rounded p-1 px-3 text-gray-600 text-lg input-phone-number"
                          placeholder="eg 0411 222 333"
                        />
                      </label>

                      {
                        patientsData && patientsData.data && patientsData.data.length > 0 && (
                          <div className="block pb-3">
                          <label className="block text-sm font-medium text-gray-700 pb-2" htmlFor="patient-select">
                            Patient Found With Exact Phone Number - Please Select One
                          </label>
                          <select 
                            id="patient-select"
                            value={selectedPatient?.id || ""}
                            onChange={(e) => handleSelectingPatient(e.target.value)}
                            className="bg-gray-100 border border-gray-300 text-sm rounded-lg block w-full p-2.5 cursor-pointer mb-1"
                          >
                            <option value="">Select a Patient</option>
                            {patientsData?.data.map((patient: PatientWithMedias) => (
                              <option key={patient.id} value={patient.id}>
                                {`${patient.firstName} ${patient.lastName}`} - {moment(patient.dateOfBirth).format("DD-MM-YYYY").toString()}
                              </option>
                            ))}
                          </select>
                        </div>
                        )
                      }

                      <label className="block pb-3">
                        <span className="block text-sm font-medium text-gray-700 pb-2">
                          Message
                        </span>
                        <textarea
                          rows={8}
                          className="w-full border rounded p-1 px-3 text-gray-600 text-lg"
                          disabled={true}
                          value={message()}
                        />
                      </label>

                      <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          onClick={() => sendTextMessage()}
                          className={`inline-flex w-full justify-center rounded-md px-3 py-2 font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${
                            buttonColor === "blue"
                              ? "bg-blue-600"
                              : buttonColor === "green"
                              ? "bg-green-600"
                              : "bg-red-600"
                          }`}
                        >
                          {isSending && (
                            <span className="inline-block h-6 w-6 mr-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
                          )}
                          {buttonText}
                        </button>
                        <button
                          type="button"
                          onClick={() => params.closeModal()}
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="sm:col-span-4 border border-gray-100 bg-gray-100">
            <ActivityFeed 
              typeOf={ActivityLogTypes.SmsRequestForm} 
              refreshFeed={refreshActivityFeed}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequestForm;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    border: "none",
    boxShadow: "#b2b2b2 -10px 10px 20px;",
    borderRadius: "10px",
  },
  overlay: {
    zIndex: 20,
    backgroundColor: "rgba(0,0,0,0.15)",
  },
};
