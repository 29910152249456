import { useMemo, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import React from "react";
import { Form, useGetFormsQuery } from "../../services/forms/FormService";
import moment from "moment";
import SearchForm from "../layout/SearchForm";
import store from "../../redux/store";
import { Location } from "../../models/Location";
import PlusIcon from "../../assets/icons/PlusIcon";
import { useNavigate } from "react-router-dom";

const FormsList: React.FC = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);

  store.subscribe(() => {
    const state = store.getState();
    setCurrentLocation(state.currentLocation);
  });

  const { data, isLoading, isError } = useGetFormsQuery({
    itemsPerPage: pageSize,
    itemOffset: pageIndex * pageSize,
    query: searchQuery,
    locationIds: currentLocation?.id ? [currentLocation.id] : null,
  }, { refetchOnMountOrArgChange: true });

  const columns = useMemo<ColumnDef<Form>[]>(
    () => [
      {
        header: "Form Type",
        accessorFn: row => row.formType.name,
      },
      {
        header: "Service Provider",
        accessorFn: row => row.serviceProvider,
      },
      {
        header: "External Form ID",
        accessorFn: row => row.externalFormId,
      },
      {
        header: "Created At",
        cell: ({ row }) => {
          const formattedDate = moment.utc(row.original.createdAt).local().format("DD/MM/YYYY");
          const formattedTime = moment.utc(row.original.createdAt).local().format("h:mm:ss A");
      
          return (
            <div className="flex flex-col">
              <p>{formattedDate}</p>
              <p className="text-xs text-gray-600">{formattedTime}</p>
            </div>
          );
        },
      },
      {
        header: "Updated At",
        cell: ({ row }) => {
          const formattedDate = moment.utc(row.original.updatedAt).local().format("DD/MM/YYYY");
          const formattedTime = moment.utc(row.original.updatedAt).local().format("h:mm:ss A");
      
          return (
            <div className="flex flex-col">
              <p>{formattedDate}</p>
              <p className="text-xs text-gray-600">{formattedTime}</p>
            </div>
          );
        },
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex space-x-2">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/forms/${row.original.id}/preview`);
              }}
              className="text-blue-500 hover:text-blue-700 cursor-pointer"
            >
              View
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/forms/${row.original.id}/edit`);
              }}
              className="text-green-500 hover:text-green-700 cursor-pointer"
            >
              Edit
            </a>
          </div>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: data?.data || [],
    columns,
    pageCount: Math.ceil((data?.metadata.totalCount || 0) / pageSize), 
    state: {
      pagination: { pageIndex, pageSize },
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  if (isLoading) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
        Error loading forms
      </div>
    );
  }

  return (
    <section className="px-12">
      <section className="w-full mx-auto pt-8 pb-4">
        <div className="w-full lg:w-auto flex items-center mb-4 lg:mb-0 flex justify-between">
          <div className="flex items-center ">
            <h2 className="text-4xl font-bold">Forms</h2>
            <span className="inline-block py-1 px-4 ml-2 rounded-full text-white bg-black">
              {data?.metadata?.totalCount || 0} Total
            </span>
          </div>

          <div className="flex">
            <button
              className="md:w-auto flex items-center py-2 px-4 rounded text-blue-400 font-medium cursor-pointer mr-2"
              onClick={() => navigate("/form_answers")}
            >
              Form Answers
            </button>
            <button
              className="md:w-auto flex items-center py-2 px-4 rounded bg-indigo-500 hover:bg-indigo-600 text-white font-medium"
              onClick={() => navigate("/forms/new")}
            >
              <span className="inline-block mr-1">
                <PlusIcon />
              </span>
              New Form
            </button>
          </div>
        </div>

        <div className="pt-4 mt-3">
          <div className="flex justify-end items-center">
            <div className="flex items-center">
              <SearchForm 
                requestSearch={(query) => {
                  setSearchQuery(query);
                }}
              />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto rounded-lg border mt-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="hover:bg-gray-50">
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="flex space-x-2">
            <button
              className="px-4 py-2 border rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </button>
            <button
              className="px-4 py-2 border rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </button>
          </div>
          
          <div className="text-sm text-gray-700">
            Page {pageIndex + 1} of {table.getPageCount()}
          </div>

          <select
            className="border rounded-md p-2"
            value={pageSize}
            onChange={e => {
              setPagination(p => ({ ...p, pageSize: Number(e.target.value) }));
            }}
          >
            {[10, 20].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </section>
    </section>
  );
};

export default FormsList;