import React from "react";
import { User } from "../../../../services/UserService";
import BaseAssignModal, { BaseAssignUserModalProps } from "./BaseAssignModal";

interface SingleAssignModalProps extends Omit<BaseAssignUserModalProps, "onSubmit"> {
  selectedFormAnswer: User | null;
  onSelectUser: (user: User | null) => void;
}

const SingleAssignModal: React.FC<SingleAssignModalProps> = ({
  isOpen,
  onClose,
  selectedFormAnswer,
  onSelectUser
}) => {
  return (
    <BaseAssignModal
      isOpen={isOpen}
      onClose={onClose}
      initialSelectedUser={selectedFormAnswer}
      onSubmit={onSelectUser}
      title="Assign User"
      submitButtonText="Assign"
    />
  );
};

export default SingleAssignModal;
