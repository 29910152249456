import { 
  ElementFactory, 
  Question, 
  Serializer, 
  Model,
} from "survey-core";
import { SurveyCreator } from "survey-creator-react";
import { ReactQuestionFactory } from "survey-react-ui";
import React from "react";
import ImagePicker from "../customQuestions/ImagePickerQuestion";
import defaultSurvey from "../default_survey.json";
import { SurveyPDF, IDocOptions } from "survey-pdf";
import { registerImagePickerForPdf } from "../customQuestions/ImagePickQuestionPdfExport";

export const pdfDocOptions: IDocOptions = {
  fontSize: 16,
  margins: {
    left: 10,
    right: 10,
    top: 20,
    bot: 10
  },
  format: [210, 297], // A4 format
  fontName: "helvetica",
  orientation: "p", // Portrait orientation
};

export class ImagePickerQuestion extends Question {
  constructor(name: string) {
    super(name);
    // Initialize with empty array
    this.setPropertyValue("value", []);
  }

  getType() {
    return "imagepicker";
  }

  getValueCore() {
    const val = super.getValueCore();
    console.log("ImagePickerQuestion getValueCore called:", val);
    return val === undefined ? [] : val;
  }

  setValueCore(newValue: any) {
    console.log("ImagePickerQuestion setValueCore called:", newValue);
    super.setValueCore(newValue);
  }

  get imageSrc() {
    return this.getPropertyValue("imageSrc", "/human_image.png");
  }
  set imageSrc(value: string) {
    this.setPropertyValue("imageSrc", value);
  }
}

export const createSurveyCreator = (options = {}) => {
  const defaultOptions = {
    showLogicTab: true,
    allowModifyPages: true,
    showJSONEditorTab: true,
    haveCommercialLicense: true,
    ...options
  };

  const creator = new SurveyCreator(defaultOptions);
  
  creator.text = JSON.stringify(defaultSurvey);
  
  return creator;
};

export const createSurveyModel = (
  surveyJson: any = defaultSurvey, 
  options: Record<string, any> = {}
) => {
  const surveyModel = new Model(surveyJson);

  surveyModel.showNavigationButtons = true;
  surveyModel.showCompletedPage = false;

  Object.keys(options).forEach((key) => {
    (surveyModel as any)[key] = options[key]; // Type assertion to avoid TS error
  });

  return surveyModel;
};

export const exportSurveyToPdf = async (survey: Model, fileName = "survey-export.pdf", data = {}): Promise<boolean> => {
  if (!survey) {
    console.error("No survey provided for PDF export");
    return false;
  }
  
  try {
    registerImagePickerForPdf();
    
    const pdfSurveyJson = JSON.parse(JSON.stringify(survey.toJSON()));
    
    if (pdfSurveyJson.pages && pdfSurveyJson.pages.length > 1) {
      const allElements: any[] = [];
      pdfSurveyJson.pages.forEach((page: any) => {
        if (page.elements && page.elements.length > 0) {
          allElements.push(...page.elements);
        }
      });
      
      pdfSurveyJson.pages = [{
        name: "consolidated_page",
        elements: allElements
      }];
    }
    
    if (pdfSurveyJson.pages) {
      pdfSurveyJson.pages.forEach((page: any) => {
        if (page.elements) {
          page.elements.forEach((element: any) => {
            if (element.type === "imagepicker") {
              if (element.name && survey.data && survey.data[element.name]) {
                element.value = survey.data[element.name];
              }
              
              if (!element.value || !Array.isArray(element.value)) {
                element.value = [];
              }
            }
          });
        }
      });
    }
    
    const surveyPDF = new SurveyPDF(pdfSurveyJson, pdfDocOptions);
    
    const exportData = Object.keys(data).length > 0 ? data : survey.data;
    console.log("Setting PDF export data:", exportData);
    surveyPDF.data = exportData;
    
    console.log("Saving PDF as:", fileName);
    await surveyPDF.save(fileName);
    console.log("PDF export completed successfully");
    return true;
  } catch (error) {
    console.error("Error exporting survey to PDF:", error);
    throw error;
  }
};

export const registerSurveyCustomComponents = () => {
  try {
    Serializer.addClass(
      "imagepicker", 
      [{
        name: "imageSrc",
        default: "/human_image.png",
        type: "string",
        category: "general",
        visibleIndex: 2
      }],
      function() {
        return new ImagePickerQuestion("");
      },
      "question"
    );
    
    ElementFactory.Instance.registerElement("imagepicker", (name) => {
      return new ImagePickerQuestion(name);
    });

    ReactQuestionFactory.Instance.registerQuestion("imagepicker", (props) => {
      return React.createElement(ImagePicker, props);
    });
    
    registerImagePickerForPdf();
    
    console.log("Custom survey components registered successfully");
    return true;
  } catch (error) {
    console.error("Error registering custom survey components:", error);
    return false;
  }
};

export const addCustomComponentsToToolbox = (creator: SurveyCreator) => {
  try {
    creator.toolbox.addItem({
      name: "imagepicker",
      title: "Pain Points on Image",
      category: "Custom",
      isCopied: true,
      iconName: "icon-image",
      json: {
        type: "imagepicker",
        name: "painpoints",
        title: "Click on the image where you feel pain",
        imageSrc: "/human_image.png"
      }
    });
    
    console.log("Custom components added to toolbox");
    return true;
  } catch (error) {
    console.error("Error adding custom components to toolbox:", error);
    return false;
  }
};

export const safeJsonParse = (jsonString: string | any, fallback = defaultSurvey) => {
  if (!jsonString) return fallback;
  
  try {
    if (typeof jsonString === "object") return jsonString;
    
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return fallback;
  }
};

export const useSurveyInitialization = () => {
  React.useEffect(() => {
    registerSurveyCustomComponents();
  }, []);
  
  return {
    createSurveyModel,
    createSurveyCreator,
    addCustomComponentsToToolbox,
    exportSurveyToPdf,
    safeJsonParse
  };
};