import React, { useState } from "react";
import { useGetUsersQuery, User } from "../../../../services/UserService";
import store from "../../../../redux/store";

export interface BaseAssignUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (user: User | null) => void;
  initialSelectedUser?: User | null;
}

interface BaseAssignModalProps extends BaseAssignUserModalProps {
  title: string | React.ReactNode;
  submitButtonText: string | ((selectedUser: User | null) => string);
}

const BaseAssignModal: React.FC<BaseAssignModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialSelectedUser = null,
  title,
  submitButtonText
}) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(initialSelectedUser);
  const { data: users } = useGetUsersQuery();
  const loggedInUser = users?.data?.find(user => user.email === store.getState().currentUser?.email);

  if (!isOpen) return null;

  const handleUserSelect = (user: User | null) => {
    setSelectedUser(user);
  };

  const handleSubmit = () => {
    onSubmit(selectedUser);
    onClose();
  };

  const getSubmitButtonText = () => {
    if (typeof submitButtonText === "function") {
      return submitButtonText(selectedUser);
    }
    return submitButtonText;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 max-w-full">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        
        <div className="mb-4">
          <div className="space-y-2">
            <div 
              className={`p-2 rounded cursor-pointer ${selectedUser === null ? "bg-blue-100 border border-blue-500" : "hover:bg-gray-100"}`}
              onClick={() => handleUserSelect(null)}
            >
              Unassign (None)
            </div>
            
            {loggedInUser && users?.data?.find(user => user.email === loggedInUser?.email) && (
              <div 
                className={`p-2 rounded cursor-pointer ${
                  selectedUser?.email === loggedInUser?.email ? "bg-blue-100 border border-blue-500" : "hover:bg-gray-100"
                }`}
                onClick={() => handleUserSelect(users?.data?.find(user => user.email === loggedInUser?.email) || null)}
              >
                Me ({loggedInUser.firstName} {loggedInUser.lastName})
              </div>
            )}
            
            {users?.data?.filter(u => u.email !== loggedInUser?.email).map(user => (
              <div 
                key={user.id} 
                className={`p-2 rounded cursor-pointer ${
                  selectedUser?.id === user.id ? "bg-blue-100 border border-blue-500" : "hover:bg-gray-100"
                }`}
                onClick={() => handleUserSelect(user)}
              >
                {user.firstName} {user.lastName}
              </div>
            ))}
          </div>
        </div>
        
        <div className="flex justify-end space-x-2">
          <button
            className="px-4 py-2 rounded bg-gray-200 hover:bg-gray-300"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded bg-indigo-500 hover:bg-indigo-600 text-white"
            onClick={handleSubmit}
          >
            {getSubmitButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BaseAssignModal;
