import React, { useEffect, useState } from "react";
import AppLayout from "../layout/AppLayout";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import MediaPatientAPI, { Referral } from "../../services/MediaPatient";
import { useGetUsersQuery } from "../../services/UserService";
import store from "../../redux/store";
import UserAPI, { CurrentUser } from "../../services/User";
import ReferralInfo from "./ReferralInfo";

const ReferralShow: React.FC = () => {
  const [referral, setReferral] = useState<Referral | null>(null);
  const referralId = useParams().referralId as string;
  const { getIdTokenClaims } = useAuth0();
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [loadingReferral, setLoadingReferral] = useState<boolean>(true);
  const { user } = useAuth0();

  useEffect(() => {
    onLoad();
  });

  const onLoad = async () => {
    if (currentUser === null) {
      const user = await fetchCurrentUser();
      if (user) {
        fetchReferral(user);
      }
    }
  };


  const { data: usersData } = useGetUsersQuery();

  const fetchReferral = async (user: CurrentUser) => {
    const token = await getAccessToken();
    const response = await MediaPatientAPI.get(user, token, referralId);

    if (response.data) {
      const referral = response.data as Referral;
      setLoadingReferral(false);
      setReferral(referral as Referral);
    } else {
      console.error("Failed to fetch referral", response.error);
      setLoadingReferral(false);
    }
  };

  const fetchCurrentUser = async () => {
    const token = await getAccessToken();
    if (user?.sub) {
      const response = await UserAPI.currentUser(user?.sub, token);

      if (response.data) {
        const user = response.data as CurrentUser;
        setCurrentUser(user);
        store.dispatch({ type: "currentUserState", payload: user });
        return user;
      } else {
        console.error("Fetch current user failed:", response.error);
      }
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims?.__raw;
      return accessToken;
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
  };

  return (
    <AppLayout>
      <section className="px-12">
        <section className="w-full mx-auto pt-8">
          {loadingReferral && <div>Loading...</div>}

          {!loadingReferral && referral && (
            <div className="bg-white rounded-lg">
              <ReferralInfo
                users={usersData?.data || []}
                referral={referral}
                refreshReferrals={() => console.log("refreshReferral") }
              />
            </div>
          )}
        </section>
      </section>
    </AppLayout>
  );
};

export default ReferralShow;
