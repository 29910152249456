import "survey-core/defaultV2.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import React, { useEffect, useState } from "react";
import defaultSurvey from "./default_survey.json";
import { useParams} from "react-router-dom";
import { useGetFormQuery } from "../../services/forms/FormService";
import { useSurveyInitialization, safeJsonParse } from "./utils/survey-utils";

const FormPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [survey, setSurvey] = useState<Model | null>(null);
  const { data: formData, isLoading, error } = useGetFormQuery(id || "");
  
  const { createSurveyModel } = useSurveyInitialization();


  useEffect(() => {
    if (formData?.data) {
      let surveyJson = defaultSurvey;
      
      if (formData.data.questionsData) {
        surveyJson = safeJsonParse(formData.data.questionsData);
      }
      
      const surveyModel = createSurveyModel(surveyJson, {
        mode: "display",
        showNavigationButtons: true,
        showCompletedPage: false
      });
      
      setSurvey(surveyModel);
    }
  }, [formData, createSurveyModel]);

  if (isLoading) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
        Error loading form
      </div>
    );
  }

  if (!survey) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }

  return (
    <Survey model={survey} />
  );
};

export default FormPreview;