import ReferralsList from "./components/referrals/ReferralsList";
import {
  createBrowserRouter,
} from "react-router-dom";

import LandingPage from "./components/LandingPage";
import PrivateRoute from "./components/authentication/PrivateRoute";

import ReferralShow from "./components/referrals/ReferralShow";
import ReferralUploadWrapper from "./components/referrals/ReferralUploadWrapper";
import React from "react";
import BookingPreference from "./components/referrals/registration/BookingPreference";
import SuccessfulRegistration from "./components/referrals/registration/SuccessfulRegistration";
import FormAnswersList from "./components/formAnswers/FormAnswersList";
import SignUp from "./components/pages/signUp/SignUp";
import FormCreator from "./components/forms/FormCreator";
import FormViewer from "./components/forms/FormViewer";
import FormsList from "./components/forms/FormsList";
import FormPreview from "./components/forms/FormPreview";
import FormResponse from "./components/forms/FormResponse";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/referrals",
    element: <PrivateRoute component={ReferralsList} />,
  },
  {
    path: "/:slug/referral_upload",
    element: <ReferralUploadWrapper />,
  },
  {
    path: "/:slug/referrals/:referralId/booking_preferences",
    element: <BookingPreference />,
  },
  {
    path: "/:slug/referrals/:referralId/success",
    element: <SuccessfulRegistration />,
  },
  {
    path: "/referrals/:referralId",
    element: <PrivateRoute component={ReferralShow} />,
  },
  {
    path: "/form_answers",
    element: <PrivateRoute component={FormAnswersList} />,
  },
  {
    path: "/sign_up",
    element: <SignUp />,
  },
  {
    path: "/forms",
    element: <PrivateRoute component={FormsList} />,
  },
  {
    path: "/forms/new",
    element: <PrivateRoute component={FormCreator} />,
  },
  {
    path: "/forms/:id/edit",
    element: <PrivateRoute component={FormCreator} />,
  },
  {
    path: "/forms/:id/preview",
    element: <FormPreview />,
  },
  {
    path: "/:slug/form_request/:id",
    element: <FormViewer />,
  },
  {
    path: "/form_answer/:id",
    element: <PrivateRoute component={FormResponse} />,
  }
]);
