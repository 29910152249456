import { useMemo, useState } from "react";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import React from "react";
import { 
  FormAnswer, 
  FormAnswerStatus, 
  useGetFormAnswersQuery, 
  useUpdateFormAnswerMutation,
  formAnswers
} from "../../services/forms/FormAnswersService";
import S3Service from "../../services/aws/S3Service";
import moment from "moment";
import SearchForm from "../layout/SearchForm";
import store from "../../redux/store";
import { Location } from "../../models/Location";
import RequestForm from "./RequestForm";
import { CurrentUser } from "../../services/User";
import PlusIcon from "../../assets/icons/PlusIcon";
import { User } from "../../services/UserService";
import UsersFilter from "../shared/filters/UsersFilter";
import { SingleAssignModal } from "../shared/selectors/AssignUser";
import { statisticsService } from "../../services/StatisticsService";
import { useNavigate } from "react-router-dom";
import { useSurveyInitialization } from "../forms/utils/survey-utils";

const FormAnswersList: React.FC = ()  => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [requestFormModalIsOpen, setRequestFormModalIsOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<FormAnswerStatus | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [unassigned1, setUnassigned] = useState<boolean | null>(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [selectedFormAnswer, setSelectedFormAnswer] = useState<FormAnswer | null>(null);
  const [exportingPdf, setExportingPdf] = useState(false);
  
  const { exportSurveyToPdf, createSurveyModel, safeJsonParse } = useSurveyInitialization();

  const handleExportToPdf = async (formAnswerId: string) => {
    try {
      setExportingPdf(true);
      
      const formAnswerResponse = await store.dispatch(
        formAnswers.endpoints.getFormAnswer.initiate(formAnswerId)
      ).unwrap();
      
      const formAnswer = formAnswerResponse.data;
      
      if (!formAnswer || !formAnswer.form || !formAnswer.answersData) {
        throw new Error("Form answer data is incomplete");
      }
      
      const formStructure = safeJsonParse(formAnswer.answersData);
      const surveyModel = createSurveyModel(formStructure, {
        mode: "display",
        showNavigationButtons: false,
        showCompletedPage: false
      });
      
      surveyModel.data = formAnswer.answersData;
      
      const patientName = `${formAnswer.patient.firstName}-${formAnswer.patient.lastName}`.toLowerCase().replace(/\s+/g, "-");
      const formType = formAnswer.form.formType.name.toLowerCase().replace(/\s+/g, "-");
      const timestamp = moment(formAnswer.updatedAt).format("YYYY-MM-DD");
      const fileName = `${patientName}-${formType}-${timestamp}.pdf`;
      
      await exportSurveyToPdf(surveyModel, fileName, formAnswer.answersData);
      
    } catch (error) {
      console.error("Error exporting form to PDF:", error);
    } finally {
      setExportingPdf(false);
    }
  };
  
  store.subscribe(() => {
    const state = store.getState();
    setCurrentLocation(state.currentLocation);
    setCurrentUser(state.currentUser);
  });

  const { data, isLoading, isError, refetch } = useGetFormAnswersQuery({
    itemsPerPage: pageSize,
    itemOffset: pageIndex * pageSize,
    query: searchQuery,
    locationIds: currentLocation?.id ? [currentLocation.id] : null,
    status: statusFilter,
    userId: user?.id || null,
    unassigned: unassigned1
  }, { refetchOnMountOrArgChange: true } );

  const [updateFormAnswer] = useUpdateFormAnswerMutation();


  const viewPdf = async (imageKey: string) => {
    const signedUrl = await S3Service.getPresignedUrl(imageKey, 1440);

    if (!signedUrl) {
      console.error("Failed to get signed URL");
      return;
    }
    window.open(signedUrl, "_blank"); 
  };

  const hasAnswerSnapForms = (formAnswer: FormAnswer) => {
    return formAnswer.pdfUrl !== null && formAnswer.pdfUrl !== "";
  };

  const hasAnsweredElvatiForms = (formAnswer: FormAnswer) => {
    return formAnswer.answersData && formAnswer.pdfUrl === "";
  };

  const handleUserChange = async (selectedUser: User | null | string) => {
    if (typeof selectedUser === "string" && selectedUser === "None") {
      setUnassigned(true);
      setUser(null);
    } else {
      setUnassigned(false);
      setUser(selectedUser as User);
    }
  };

  const columns = useMemo<ColumnDef<FormAnswer>[]>(
    () => [
      {
        header: "Patient",
        accessorFn: row => `${row.patient.firstName} ${row.patient.lastName}`,
      },
      {
        header: "Mobile phone",
        accessorFn: row => row.patient.mobilePhoneNumber,
      },
      {
        header: "DOB",
        accessorFn: row => moment(row.patient.dateOfBirth).format("DD-MM-YYYY").toString(),
      },
      {
        header: "Assigned To",
        cell: ({ row }) => {
          const formAnswer = row.original;
          const userName = formAnswer.user ? 
            `${formAnswer.user.firstName} ${formAnswer.user.lastName || ""}` : 
            "N/A";
          
          return (
            <div>
              <button
                className="text-blue-500 hover:text-blue-700 hover:underline cursor-pointer"
                onClick={() => {
                  setSelectedFormAnswer(formAnswer);
                  setUserModalOpen(true);
                }}
              >
                {userName}
              </button>
            </div>
          );
        }
      },
      {
        header: "Status",
        cell: ({ row }) => {
          return (
              <span
                className={`p-4 rounded-full py-1 rounded text-white ${
                  row.original.status === "pending"
                    ? "bg-yellow-500"
                    : row.original.status === "in_progress"
                    ? "bg-blue-500"
                    : row.original.status === "completed"
                    ? "bg-green-500"
                    : "bg-gray-500"
                }`}
              >
                {row.original.status}
              </span>
          );
        },
      },
      {
        header: "Form Type",
        cell: ({ row }) => {
          return (
            <button className="cursor-pointer text-blue-400"onClick={() => navigate(`/forms/${row.original.form.id}/preview`)}>
              {row.original.form.formType.name}
            </button>
          );
        }
      },
      {
        header: "Updated at",
        cell: ({ row }) => {
          const formattedDate = moment.utc(row.original.updatedAt).local().format("DD/MM/YYYY");
          const formattedTime = moment.utc(row.original.updatedAt).local().format("h:mm:ss A");
      
          return (
            hasAnswerSnapForms(row.original) && (
              <div className="flex flex-col">
                <p>{formattedDate}</p>
                <p className="text-xs text-gray-600">{formattedTime}</p>
              </div>
            )
          );
        },
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex flex-col">
            {hasAnswerSnapForms(row.original) && (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    viewPdf(row.original.pdfUrl);
                  }}
                  className="text-blue-500 hover:text-blue-700 cursor-pointer"
                >
                  Download
                </a>
            )}

            {hasAnsweredElvatiForms(row.original) && (
              <>
                <button 
                  onClick={() => handleNavigation(row.original.id)}
                  className="text-blue-500 hover:text-blue-700 cursor-pointer"
                >
                  View
                </button>
                
                <button 
                  onClick={() => handleExportToPdf(row.original.id)}
                  className="text-green-500 hover:text-green-700 cursor-pointer flex items-center"
                  disabled={exportingPdf}
                >
                  {exportingPdf ? (
                    <>
                      <span className="inline-block h-3 w-3 mr-1 animate-spin rounded-full border-2 border-solid border-green-600 border-r-transparent" />
                      Exporting...
                    </>
                  ) : (
                    "Download"
                  )}
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    [exportingPdf, handleExportToPdf]
  );

  const handleNavigation = (formAnswerId: string) => {
    navigate(`/form_answer/${formAnswerId}`);
  };

  const table = useReactTable({
    data: data?.data || [],
    columns,
    pageCount: Math.ceil((data?.metadata.totalCount || 0) / pageSize), 
    state: {
      pagination: { pageIndex, pageSize },
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  if (isLoading) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
        Error loading form answers page
      </div>
    );
  }

  const closeRequestReferralModal = () => {
    setRequestFormModalIsOpen(false);
  };

 
  const tabOptions = [
    { label: "All", value: null },
    { label: "Completed", value: "completed" },
    { label: "In Progress", value: "in_progress" },
    { label: "Pending", value: "pending" },
  ];

  const resetFilters = () => {
    setStatusFilter(null);
    setUser(null);
    setUnassigned(null);
  };

  const handleUserAssignment = async (selectedUser: User | null) => {
    if (selectedFormAnswer) {
      try {
        const wasUnassigned = !selectedFormAnswer.user;
        const willBeUnassigned = !selectedUser;
        
        await updateFormAnswer({
          id: selectedFormAnswer.id,
          body: {
            userId: selectedUser?.id || null
          }
        }).unwrap();
        
        refetch(); // fetch form answers
        
        if (wasUnassigned !== willBeUnassigned) { // fetch unassigned stats for side bar nav
          store.dispatch(
            statisticsService.util.invalidateTags(["SidebarStatistics"])
          );
        }
      } catch (error) {
        console.error("Failed to update user assignment:", error);
      }
    }
  };

  const selectedUserValue = () => {
    if (user) {
      return `${user.firstName}`;
    }

    if (unassigned1) {
      return "Unassigned";
    }

    return "All";
  };

  return (
    <section className="px-12">
      <section className="w-full mx-auto pt-8 pb-4">
        {
          currentUser && <RequestForm
            isOpen={requestFormModalIsOpen}
            closeModal={closeRequestReferralModal}
            currentUser={currentUser}
            resetGetForms={refetch}
          />
        }
        
        <SingleAssignModal
          isOpen={userModalOpen}
          onClose={() => setUserModalOpen(false)}
          selectedFormAnswer={selectedFormAnswer?.user || null}
          onSelectUser={handleUserAssignment}
        />
        <div className="w-full lg:w-auto flex items-center mb-4 lg:mb-0 flex justify-between">
          <div className="flex items-center ">
            <h2 className="text-4xl font-bold">Form Responses</h2>
            <span className="inline-block py-1 px-4 ml-2 rounded-full text-white bg-black">
              {data?.metadata?.totalCount || 0} Total
            </span>
          </div>

          <div className="flex">
            <button
              className="md:w-auto flex items-center py-2 px-4 rounded text-blue-400 font-medium cursor-pointer"
              onClick={() => navigate("/forms")}
            >
              Forms
            </button>
            <button
              className="md:w-auto flex items-center py-2 px-4 rounded bg-indigo-500 hover:bg-indigo-600 text-white font-medium"
              onClick={() => setRequestFormModalIsOpen(true)}
            >
              <span className="inline-block mr-1">
                <PlusIcon />
              </span>
              Request Form
            </button>
          </div>
        </div>

        <div className="pt-4 mt-3">
          <div className="flex  justify-between items-center">
            <div className="flex">
              {tabOptions.map((tab) => (
                <button
                  key={tab.value}
                  className={`px-4 py-2  transition-all rounded-lg ${
                    statusFilter === tab.value
                      ? "bg-indigo-500 text-white"
                      : "text-black"
                  }`}
                  onClick={() => setStatusFilter(tab.value as FormAnswerStatus)}
                >
                  {tab.label}
                </button>
              ))}

              <div className="ml-4">
                <UsersFilter selectedUser={selectedUserValue()} onSelectUser={handleUserChange} unassignedCallBack={setUnassigned}/>
              </div>

              <button className="pl-3 text-sm font-bold text-blue-600 cursor-pointer" onClick={resetFilters}> Reset </button>
            </div>

            <div className="flex items-center">
              <SearchForm 
                requestSearch={(query) => {
                  setSearchQuery(query);
                }}
              />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto rounded-lg border mt-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="hover:bg-gray-50">
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="flex space-x-2">
            <button
              className="px-4 py-2 border rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </button>
            <button
              className="px-4 py-2 border rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </button>
          </div>
          
          <div className="text-sm text-gray-700">
            Page {pageIndex + 1} of {table.getPageCount()}
          </div>

          <select
            className="border rounded-md p-2"
            value={pageSize}
            onChange={e => {
              setPagination(p => ({ ...p, pageSize: Number(e.target.value) }));
            }}
          >
            {[10, 20].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </section>
    </section>
  );
};

export default FormAnswersList;
