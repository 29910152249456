import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import store from "../../redux/store";
import { CurrentUser } from "../User";
import { Patient } from "../MediaPatient";
import { User } from "../UserService";

interface Location {
  id: string
  name: string
}

interface Form {
  id: string,
  formType: FormType,
}

export interface FormType {
  id: string
  name: string,
  createdAt: string,
  updatedAt: string,
}

export interface FormAnswer {
  id: string
  tenantId: string
  location: Location
  form: Form
  patient: Patient
  user: User | null
  pdfUrl: string
  answersData?: Record<string, unknown> | null
  status: FormAnswerStatus
  createdAt: string
  updatedAt: string
}

export enum FormAnswerStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
}

interface FormAnswersResponseApiResponse {
  data: FormAnswer[],
  metadata: {
    totalCount: number
  }
}

interface FormAnswersRequestParams {
  itemsPerPage?: number
  itemOffset?: number
  query: string | null
  locationIds: string[] | null
  status: FormAnswerStatus | null
  userId: string | null
  unassigned: boolean | null
}

interface FormAnswerRequest {
  formId: string;
  patientId: string;
  locationId: string;
  answersData: Record<string, string> | null;
}

interface FormAnswerResponse {
  data?: FormAnswer | null;
  errors?: string[];
}

interface UpdateFormAnswerRequest {
  id: string;
  body: {
    userId: string | null;
    answersData?: Record<string, string> | null;
  };
}

export const formAnswers = createApi({
  reducerPath: "surveyResponsesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers) => {
      const accessToken = store.getState().accessToken;
      const currentUser = store.getState().currentUser as CurrentUser;

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      headers.set("Tenant-Slug", currentUser.tenantSlug);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFormAnswers: builder.query<FormAnswersResponseApiResponse, FormAnswersRequestParams>({
      query: ({
        itemsPerPage = 10,
        itemOffset = 0,
        query,
        locationIds,
        status,
        userId,
        unassigned
      }) => {
        let apiUrl = `/form_answers?itemsPerPage=${itemsPerPage}&itemOffset=${itemOffset}`;

        if (query) {
          apiUrl += `&query=${query}`;
        }

        if (locationIds) {
          apiUrl += `&locationIds=${locationIds.join(",")}`;
        }

        if (status) {
          apiUrl += `&status=${status}`;
        }

        if (userId) {
          apiUrl += `&userId=${userId}`;
        }

        if (unassigned) {
          apiUrl += `&unassigned=${unassigned}`;
        }

        return {
          url: apiUrl,
          method: "GET",
        };
      },
      keepUnusedDataFor: 3,
    }),
    getFormAnswer: builder.query<FormAnswerResponse, string>({
      query: (id) => ({
        url: `/form_answers/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 3,
    }),
    createFormAnswer: builder.mutation<FormAnswerResponse, FormAnswerRequest>({
      query: (body) => ({
        url: "/form_answers",
        method: "POST",
        body,
      }),
    }),
    updateFormAnswer: builder.mutation<FormAnswerResponse, UpdateFormAnswerRequest>({
      query: ({ id, body }) => ({
        url: `/form_answers/${id}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const { 
  useGetFormAnswersQuery, 
  useGetFormAnswerQuery,
  useCreateFormAnswerMutation, 
  useUpdateFormAnswerMutation 
} = formAnswers;


