import React from "react";
import { useState } from "react";
import arrowDownIcon from "../../../assets/icons/arrowDown.svg";
import { useGetUsersQuery, User } from "../../../services/UserService";
import { fullName, OptionParam } from "../../referrals/ReferralsListFilters";


type UserFilterProps = {
  selectedUser: string;
  onSelectUser: (user: User | null) => void;
  unassignedCallBack: (unassigned: boolean) => void;
};

const UserFilter: React.FC<UserFilterProps> = ({ selectedUser, onSelectUser, unassignedCallBack }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { data: usersData } = useGetUsersQuery();

  const usersList: OptionParam[] = [
    { name: "All", value: null },
    ...(usersData?.data?.map((user: User) => ({
      name: fullName(user),
      value: user.id,
    })) || []).sort((a, b) => a.name.localeCompare(b.name)),
  ];

  return (
    <div className="relative assigned-to-dropdown pr-2">
      <button
        className="border border-gray-400 p-2 bg-white flex items-center rounded text-sm font-medium capitalize leading-normal text-primary"
        type="button"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span className="flex relative font-bold">
          <p className="mr-2">Assigned To:</p>
          <p className="text-blue-600">{selectedUser}</p>
          <img src={arrowDownIcon} alt="Arrow Down" className="w-5 h-5" />
        </span>
      </button>

      {isDropdownOpen && (
        <ul
          className="border border-gray-600 absolute z-[1000] float-left mt-1 min-w-max list-none overflow-hidden rounded-lg bg-white shadow-lg"
        >
          {usersList.map((assignedTo: OptionParam) => (
            <li key={assignedTo.value || "all"} className="cursor-pointer">
              <div
                className="block py-2 px-4 capitalize border-b hover:bg-gray-100"
                onClick={() => {
                  const selected = assignedTo.value
                    ? usersData?.data?.find((user) => user.id === assignedTo.value) || null
                    : null;
                  onSelectUser(selected);
                  setIsDropdownOpen(false);
                }}
              >
                {assignedTo.name}
              </div>
            </li>
          ))}
          <button 
            onClick={() => {
              onSelectUser(null);
              setIsDropdownOpen(false);
              unassignedCallBack(true);
            }} 
            className="block py-2 px-4 capitalize border-b hover:bg-gray-100"
          >
              Unassigned
          </button>
        </ul>
      )}
    </div>
  );
};

export default UserFilter;
