import React from "react";
import { User } from "../../../../services/UserService";
import BaseAssignModal, { BaseAssignUserModalProps } from "./BaseAssignModal";

interface BulkAssignModalProps extends Omit<BaseAssignUserModalProps, "onSubmit"> {
  onBulkAssign: (user: User | null) => void;
  referralCount: number;
}

const BulkAssignModal: React.FC<BulkAssignModalProps> = ({
  isOpen,
  onClose,
  onBulkAssign,
  referralCount
}) => {
  return (
    <BaseAssignModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onBulkAssign}
      title={
        <>
          Assign {referralCount} Referral{referralCount > 1 ? "s" : ""} to User
        </>
      }
      submitButtonText={(selectedUser) => selectedUser === null ? "Unassign" : "Assign"}
    />
  );
};

export default BulkAssignModal;
