import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface RegistrationFormProps {
  onRegister: (formData: RegistrationFormData) => void;
}

export interface RegistrationFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyWebsite: string;
  locationCount: number;
  recaptchaToken?: string;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onRegister }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<RegistrationFormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyWebsite: "",
    locationCount: 1
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "locationCount" ? parseInt(value) || 1 : value
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    onRegister(formData);
    navigate("/sign_up?page=completed");
  };

  const handleBack = () => {
    navigate("/sign_up?page=features");
  };

  return (
    <div className="max-w-lg mx-auto w-full p-4 md:p-4">
      <h2 className="text-2xl md:text-3xl font-bold mb-6">
        Complete Your Registration
      </h2>
      <p className="text-gray-600 mb-8">
        Please provide your details to create your account.
      </p>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              required
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              required
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            required
            value={formData.phoneNumber}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="companyWebsite" className="block text-sm font-medium text-gray-700 mb-1">
            Company Website
          </label>
          <input
            type="text"
            id="companyWebsite"
            name="companyWebsite"
            required
            value={formData.companyWebsite}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="locationCount" className="block text-sm font-medium text-gray-700 mb-1">
            Number of Locations
          </label>
          <input
            type="number"
            id="locationCount"
            name="locationCount"
            min="1"
            required
            value={formData.locationCount}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        
        <div className="pt-4 flex space-x-4">
          <button
            type="button"
            onClick={handleBack}
            className="w-1/3 py-3 px-6 border hover:bg-gray-50 text-gray-800 font-semibold rounded-lg transition duration-200"
          >
            Back
          </button>
          <button
            type="submit"
            className="w-2/3 py-3 px-6 bg-brand-blue-dark hover:bg-brand-brown-dark text-white font-semibold rounded-lg transition duration-200"
          >
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
