import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import store from "../../redux/store";
import { Patient } from "../MediaPatient";
import { Form } from "./FormService";

export interface PatientFormRequest {
  id: string;
  tenantId: string;
  form: Form;
  patient: Patient;
  publicId: string;
  expiresAt: string;
  createdAt: string;
  updatedAt: string;
}

interface PatientFormRequestResponse {
  data?: PatientFormRequest | null;
  errors?: string[];
}

export interface CreatePatientFormRequestRequest {
  formId: string;
  patientId: string;
}

export const patientFormRequests = createApi({
  reducerPath: "patientFormRequestsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers) => {
      const accessToken = store.getState().accessToken;

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createPatientFormRequest: builder.mutation<
      PatientFormRequestResponse,
      { body: CreatePatientFormRequestRequest; tenantId: string }
    >({
      query: ({body, tenantId}) => ({
        url: "/patient_form_requests",
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
          "Tenant-Slug": tenantId,
        },
      }),
    }),
    getPublicPatientFormRequest: builder.query<
      PatientFormRequestResponse, 
      { tenantId: string | undefined; publicId: string }
    >({
      query: ({ tenantId, publicId }) => ({
        url: `/public/patient_form_requests/${publicId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Tenant-Slug": tenantId,
        },
      }),
    }),
    submitFormAnswers: builder.mutation<void, 
      { patientFormRequestId: string, questionAnswers: any, tenantId: string | undefined }
    >({
      query: ({ patientFormRequestId, questionAnswers, tenantId }) => ({
        url: `/public/patient_form_requests/${patientFormRequestId}/answer`,
        method: "PATCH",
        body: { questionAnswers: questionAnswers },
        headers: {
          "Content-Type": "application/json",
          "Tenant-Slug": tenantId,
        },
      }),
    }),
  }),
});

export const { 
  useCreatePatientFormRequestMutation,
  useGetPublicPatientFormRequestQuery,
  useSubmitFormAnswersMutation
} = patientFormRequests;