import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import consolidateSvg from "../../../assets/icons/consolidate.svg";
import smsSvg from "../../../assets/icons/communication.svg";
import formSvg from "../../../assets/icons/form.svg";
import timeIsMoneySvg from "../../../assets/icons/time-is-money.svg";
import portalSvg from "../../../assets/icons/portal.svg";
import xRaySvg from "../../../assets/icons/x-ray.svg";
import bookingSvg from "../../../assets/icons/booking.svg";
import destinationSvg from "../../../assets/icons/destinations.svg";
import RegistrationForm, { RegistrationFormData } from "./RegistrationForm";
import SuccessRegistration from "./SuccessRegistration";
import aboutUsImg from "../../../assets/images/landing_page/about-us.png";
import patientPhoneImage from "../../../assets/images/landing_page/patient-with-phone.png";
import patientFormsImage from "../../../assets/images/landing_page/patient-forms.png";
import paymentsImage from "../../../assets/images/landing_page/payment.png";
import moment from "moment";

interface Feature {
  id: string;
  title: string;
  description: string;
  icon: string;
  selected: boolean;
  isOther?: boolean;
}

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get("page") || "features";
  const typeFormContactUsForm = "https://8mt2mee3ezj.typeform.com/to/eR2meZA5";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otherFeatureText, setOtherFeatureText] = useState<string>("");
  const [features, setFeatures] = useState<Feature[]>([
    {
      id: "feature-1",
      title: "Appointment Scheduling and Management",
      description: "Manage and optimize appointment scheduling, including reminders and rescheduling.",
      icon: bookingSvg,
      selected: false
    },
    {
      id: "feature-2",
      title: "Referral Management",
      description: "Consolidate and manage referrals from various sources, including phone handling.",
      icon: consolidateSvg,
      selected: false
    },
    {
      id: "feature-3",
      title: "Patient Communication",
      description: "Automate communication via SMS, email, or portals for updates and reminders.",
      icon: smsSvg,
      selected: false
    },
    {
      id: "feature-4",
      title: "Form Digitization and Management",
      description: "Digitize and manage patient forms for easy access and updates.",
      icon: formSvg,
      selected: false
    },
    {
      id: "feature-5",
      title: "Procedure Selection and Decision Support",
      description: "Assist in selecting procedures based of their referral.",
      icon: xRaySvg,
      selected: false
    },
    {
      id: "feature-6",
      title: "Multi-Site Operations Management",
      description: "Support operations across multiple clinic locations for consistency.",
      icon: destinationSvg,
      selected: false
    },
    {
      id: "feature-7",
      title: "No-Show Management",
      description: "Reduce no-shows with strategies like holding payments or reminders.",
      icon: timeIsMoneySvg,
      selected: false
    },
    {
      id: "feature-8",
      title: "Financial Management",
      description: "Automate billing, claims processing, and insurance verification.",
      icon: portalSvg,
      selected: false
    },
    {
      id: "feature-other",
      title: "Other",
      description: "Tell us about other features you'd like to see.",
      icon: portalSvg,
      selected: false,
      isOther: true
    }
  ]);

  const toggleFeature = (id: string) => {
    setFeatures(
      features.map(feature => 
        feature.id === id 
          ? { ...feature, selected: !feature.selected } 
          : feature
      )
    );
  };

  const handleNext = () => {
    navigate("/sign_up?page=register");
  };

  const selectedCount = features.filter(feature => feature.selected).length;

  const handleRegister = async (registration: RegistrationFormData) => {
    console.log("Registration data:", registration);
    console.log("Selected features:", features.filter(feature => feature.selected));
  
    const selectedFeatures = features.filter(feature => feature.selected);
    const otherFeature = selectedFeatures.find(feature => feature.isOther);
    
    const featureText = selectedFeatures
      .filter(feature => !feature.isOther)
      .map(feature => feature.title)
      .join(", ");
      
    const data = {
      contact: registration,
      features: `Features they're interested in: ${featureText}${
        otherFeature ? `, Other: ${otherFeatureText}` : ""
      }`
    };

    console.log("Data to send to HubSpot:", data);
  
    try {
      const response = await fetch("https://hook.us2.make.com/4sesswklunklbca2g8441fiw8jdom1db", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${await response.text()}`);
      }
  
      const result = await response.json();
      console.log("Lead created successfully:", result);
    } catch (error) {
      console.error("Failed to create lead:", error);
    }
  };
  

  return (
    <div className="flex flex-col md:flex-row h-screen overflow-hidden relative">
      {/* Left side - visible only on md screens and above, fixed position */}
      <div className="hidden md:flex md:w-1/2 bg-black relative overflow-hidden flex-col items-center justify-center">
        <div className="z-10 flex flex-col items-center justify-center h-full text-white" style={{width: "500px"}}>
          <h1 className="text-5xl block uppercase font-bold text-center mb-6 cursor-pointer" onClick={() => navigate("/")}>
            ELVATI
          </h1>
          <p className="text-xl text-center mb-10">
            Create your own adminstrative workflow so you can focus on patient care.
          </p>

          <div style={{width: "500px"}}>
            <div className="flex  mb-4" >
              <img src={patientPhoneImage} alt="Patient Journey" className="w-14 h-14" />
              <div className="flex flex-col ml-4">
                <div className="flex items-center pb-2">
                  <p className="font-bold pr-2 text-lg">
                    Confirmation SMS
                  </p>
                  <div className="border border-green-500 text-green-500 px-2  rounded">
                    <div className="flex items-center text-sm">
                      Completed
                      <span className="ml-2 text-green-500 text-xl">&#10003;</span>
                    </div>
                  </div>
                </div>
                <p className="text-sm">
                  Deter patient from shopping around by sending an SMS the moment their referral is received.
                </p>
              </div>
            </div>

            <div className="flex  mb-4">
              <img src={patientFormsImage} alt="Patient Journey" className="w-14 h-14" />
              <div className="flex flex-col ml-4">
                <div className="flex items-center pb-2">
                  <p className="font-bold pr-2 text-lg">
                    Patient Booking And Registration
                  </p>
                  <div className="border border-green-500 text-green-500 px-2  rounded">
                    <div className="flex items-center text-sm">
                      Completed
                      <span className="ml-2 text-green-500 text-xl">&#10003;</span>
                    </div>
                  </div>
                </div>
                <p className="text-sm">
                  Send patient a secure online form to select their booking time and complete patient registration form.
                </p>
              </div>
            </div>

            <div className="flex  mb-4">
              <img src={paymentsImage} alt="Patient Journey" className="w-14 h-14" />
              <div className="flex flex-col ml-4">
                <div className="flex items-center pb-2">
                  <p className="font-bold pr-2 text-lg">
                    No-Show Protection
                  </p>
                  <div className="border border-yellow-500 text-yellow-500 px-2  rounded">
                    <div className="flex items-center text-sm py-1">
                      Pending
                      <span className="inline-block ml-2 h-3 w-3 border-2 border-yellow-500 border-solid border-t-transparent rounded-full animate-spin" />
                    </div>
                  </div>
                </div>
                <p className="text-sm">
                  Authorize a holding payment to protect against no show.
                </p>
              </div>
            </div>

            <div className="flex justify-center rounded p-2 rounded mt-4 text-blue-400">+ Create another task</div>
          </div>
        </div>


        <section className="overflow-hidden text-white">
          <div className="container mx-auto">
            <p className="text-center py-8 text-sm">
              © Copyright {moment().year()} - All Rights Reserved by Elvati PTY
              LTD - ACN: 675 878 270 - <span className="text-blue-500 cursor-pointer" onClick={() => window.open(typeFormContactUsForm, "_blank")}>Contact Us</span> - <span className="text-blue-500 cursor-pointer" onClick={() => navigate("/")}>Website</span>
            </p>
          </div>
        </section>
      </div>

      {/* Right side - scrollable content */}
      <div className="flex-1 overflow-y-auto pb-20">
        <div className="md:hidden flex bg-black text-gray-700 p-6 md:mb-8 ">
          <img src={aboutUsImg} alt="About Us" className="w-1/4 rounded" />
          <div className="flex flex-col ml-4 text-white">
            <h1 className="text-3xl font-bold ">
              Elvati
            </h1>
            <p className="">
              We automate patient journeys so you can focus on patient care.
            </p>
          </div>
        </div>

        {currentPage === "features" ? (
          <div className="max-w-lg mx-auto w-full p-4">
            {/* Feature selection view */}
            <h2 className="text-3xl md:text-3xl font-bold mb-6">
              We Automate and Streamline Administrative Healthcare Tasks
            </h2>
            <p className="text-gray-600 mb-8">
              Please select what domains of the administrative workflow you want to improve. Or choose other if it&apos;s not listed.
            </p>

            {/* Feature list */}
            <div className="space-y-4 mb-10">
              {features.map((feature) => (
                <div 
                  key={feature.id}
                  onClick={() => toggleFeature(feature.id)}
                  className={`p-4 border rounded-lg cursor-pointer transition-all duration-200 ${
                    feature.selected 
                      ? "border-blue-400 bg-blue-50" 
                      : "border-black hover:bg-gray-100 hover:shadow-sm"
                  }`}
                >
                  <div className="flex items-start">
                    <div className="mr-4 rounded-full">
                      <img src={feature.icon} alt={feature.title} className="w-12 h-12" />
                    </div>
                    <div className="flex-grow">
                      <h3 className="font-semibold text-lg">{feature.title}</h3>
                      <p className="text-gray-600">{feature.description}</p>
                      {feature.isOther && feature.selected && (
                        <input
                          type="text"
                          value={otherFeatureText}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => setOtherFeatureText(e.target.value)}
                          placeholder="What feature would you like?"
                          className="mt-2 p-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      )}
                    </div>
                    <div className="ml-auto">
                      <div className={`w-6 h-6 rounded-full border ${
                        feature.selected 
                          ? "bg-blue-600 border-brand-blue" 
                          : "border-gray-300"
                      }`}>
                        {feature.selected && (
                          <svg 
                            className="w-6 h-6 text-white" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth="3" 
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : currentPage === "register" ? (
          <RegistrationForm onRegister={handleRegister} />
        ) : (
          <SuccessRegistration />
        )}
      </div>

      {currentPage === "features" && (
        <div className="fixed inset-x-0 bottom-0 border-t border-gray-300 bg-white shadow-lg p-4 md:left-1/2 md:w-1/2 md:right-0">
          <div className="w-full">
            <div className="flex justify-between items-center max-w-lg mx-auto w-full md:px-4">
              <div className="text-gray-600 text-center">
                {`You have selected ${selectedCount} feature${selectedCount === 1 ? "" : "s"}`}
              </div>
              <button
                onClick={handleNext}
                className="py-3 px-6 bg-brand-blue-dark hover:bg-brand-brown-dark text-white font-semibold rounded-lg transition duration-200"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
