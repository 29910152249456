import { 
  Question,
} from "survey-core";
import { PdfBrick, DocController, FlatRepository, FlatQuestion } from "survey-pdf";
import { Point } from "./ImagePickerQuestion";
import { ImagePickerQuestion } from "../utils/survey-utils";

export const registerImagePickerForPdf = () => {
  try {
    console.log("Registering custom ImagePicker PDF renderer");
    
    FlatRepository.register("imagepicker", FlatImagePicker);
    
    console.log("Successfully registered ImagePicker PDF renderer");
  } catch (error) {
    console.error("Error registering ImagePicker for PDF:", error);
  }
};

class ImagePickerBrick extends PdfBrick {
  constructor(question: Question, controller: DocController, rect: any) {
    super(question, controller, rect);
  }

  async renderInteractive(): Promise<void> {
    const doc = this.controller.doc;
    const question = this.question as ImagePickerQuestion;
    
    const x = this.xLeft;
    const y = this.yTop;
    const width = 500;
    const height = width * (1010 / 1198);
    
    const imageSrc = question.imageSrc || "/human_image.png";
    

    doc.setDrawColor(200, 200, 200);
    doc.setFillColor(250, 250, 250);
    doc.rect(x, y, width, height, "FD");
    
    let points: Point[] = [];
    try {
      if (question.value && Array.isArray(question.value)) {
        points = question.value;
      }
    } catch (valueError) {
      console.error("Error getting points from question:", valueError);
    }

    let imagePath = imageSrc;
    if (imagePath.startsWith("/")) {
      imagePath = window.location.origin + imagePath;
    }
    
    try {
      console.log("Drawing image to PDF:", imagePath);
      doc.addImage(imagePath, "PNG", x + 5, y + 5, width - 10, height - 10);

      if (points && points.length > 0) {
        const imgX = x + 5;
        const imgY = y + 5;
        const imgWidth = width - 10;
        const imgHeight = height - 10;
        console.log("Drawing points on PDF:", points);
        this.drawPoints(doc, imgX, imgY, imgWidth, imgHeight, points);
      }
    } catch (imgError) {
      console.error("Could not add image to PDF", imgError);
      
      doc.setDrawColor(180, 180, 180);
      doc.setFillColor(240, 240, 240);
      doc.rect(x + 10, y + 10, width - 20, height - 20, "FD");
      
      doc.setFontSize(12);
      doc.text("Human body image", x + width/2 - 40, y + height/2);
    }
    
    doc.setFontSize(10);
    doc.text("Image picker question", x + 5, y + height + 10);
      
  }
  
  drawPoints(doc: any, x: number, y: number, width: number, height: number, points: Point[]) {
    const currentFillColor = doc.getFillColor();
    
    doc.setFillColor(255, 0, 0);
    
    points.forEach(point => {
      try {
        if (typeof point.x === "number" && typeof point.y === "number") {
          const pointX = x + (point.x / 100) * width;
          const pointY = y + (point.y / 100) * height;
          
          doc.circle(pointX, pointY, 5, "F");
        }
      } catch (e) {
        console.error("Error drawing point:", e);
      }
    });
    
    doc.setFillColor(currentFillColor);
  }
}

class FlatImagePicker extends FlatQuestion {
  async generateFlatsContent(point: any) {
    try {
      console.log("Generating flats content for ImagePicker");
      
      const rect = { ...point };
      rect.xRight = point.xLeft + 300; // Set width
      rect.yBot = point.yTop + 180;    // Set height
      rect.width = rect.xRight - rect.xLeft;
      rect.height = rect.yBot - rect.yTop;
      
      return [new ImagePickerBrick(this.question, this.controller, rect)];
    } catch (e) {
      console.error("Error creating image picker brick:", e);
      return [];
    }
  }
}

