import React from "react";
import greenTickAnimation from "../../../assets/images/green_tick_animation.gif";

const SuccessRegistration: React.FC = () => {
  return (
    <div className="max-w-lg mx-auto w-full px-4 md:p-4 text-center">
      <div className="flex flex-col items-center justify-center md:py-10">
        <img 
          src={greenTickAnimation} 
          alt="Success" 
          className="w-60"
        />
        <h2 className="text-2xl md:text-3xl font-bold mb-4">
          Registration Successful!
        </h2>
        <p className="text-gray-600 mb-8 text-lg">
          Thank you for registering with Elvati. Our team will be in touch with you shortly to discuss the next steps.
        </p>
        <p className="text-gray-600 mb-8">
          We&apos;re excited to partner with you to streamline your patient journeys.
        </p>
        <a 
          href="/" 
          className="py-3 px-6 bg-brand-blue-dark hover:bg-brand-brown-dark text-white font-semibold rounded-lg transition duration-200"
        >
          Return to Home
        </a>
      </div>
    </div>
  );
};

export default SuccessRegistration;