import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import portal from "../../assets/icons/portal.svg";
import form from "../../assets/icons/dataCollection.svg";
import { useGetSidebarStatisticsQuery } from "../../services/StatisticsService";
import { useSelector } from "react-redux";
import store, { RootState } from "../../redux/store";
import { CurrentUser } from "../../services/User";

const SideNavBar: React.FC = ()  => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const isReferralsPage = location.pathname === "/referrals";
  const isFormAnswersPage = location.pathname === "/form_answers";
  const currentLocation = useSelector((state: RootState) => state.currentLocation);
  const locationIds = currentLocation ? [currentLocation.id.toString()] : [];
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  
  store.subscribe(() => {
    const state = store.getState();
    setCurrentUser(state.currentUser);
  });

  const { data: sidebarStats, isLoading } = useGetSidebarStatisticsQuery({ 
    locationIds
  }, {
    skip: !currentUser
  });

  return(
    <nav 
      className="fixed left-0 flex flex-col h-screen w-3/4 lg:w-80 sm:max-w-xs bg-white overflow-y-auto" 
      style={{ borderRight: "1px solid #e4e4e4", width: "90px" }}
    >
      {/* Header */}
      <div 
        className="flex justify-center items-center h-[65px]"
        style={{borderBottom: "1px solid #e4e4e4"}}
      >
        <h2 className="text-xl font-bold">Elvati</h2>
      </div>

      {/* Menu Items - Flex wrapper to push logout to bottom */}
      <div className="flex flex-col flex-1">
        <ul className={`text-sm font-medium ${isReferralsPage && "bg-gray-100"}`}>
          <li>
            <button 
              onClick={() => navigate("/referrals")} 
              className="flex flex-col py-4 justify-center items-center w-full hover:bg-gray-100 relative"
            >
              <img src={portal} alt="Camera Icon" className="w-8 h-8 pb-1" />
              {!isLoading && sidebarStats?.data?.unassignedReferralsCount !== null && (
                <div className="absolute top-2 right-[15px] shadow-sm bg-red-500 text-white text-xxs rounded-full w-5 h-5 flex items-center justify-center">
                  {sidebarStats?.data?.unassignedReferralsCount}
                </div>
              )}
              <span className="text-black text-xs">Referrals</span>
            </button>
          </li>
        </ul>

        <ul className={`text-sm font-medium ${isFormAnswersPage && "bg-gray-100"}`}>
          <li>
            <button 
              onClick={() => navigate("/form_answers")} 
              className="flex flex-col py-4 justify-center items-center w-full hover:bg-gray-100 relative"
            >
              <img src={form} alt="Camera Icon" className="w-8 h-8" />
              {!isLoading && sidebarStats?.data?.unassignedFormsCount !== null && (
                <div className="absolute top-2 right-[15px] shadow-sm bg-red-500 text-white text-xxs rounded-full w-5 h-5 flex items-center justify-center">
                  {sidebarStats?.data.unassignedFormsCount}
                </div>
              )}
              <span className="text-black text-xs">Forms</span>
            </button>
          </li>
        </ul>

        {/* Push Logout to Bottom */}
        <div className="mt-auto">
          <ul className="text-sm font-medium">
            <li>
              <button 
                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} 
                className="flex flex-col py-4 justify-center items-center w-full hover:bg-gray-100"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                </svg>
                <span className="text-black text-xs">Log out</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  );
};

export default SideNavBar;
