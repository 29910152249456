// api/userApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CurrentUser } from "./User";
import store from "../redux/store";

// Define the User interface (same as yours)
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhoneNumber: string;
}

// Define the response type
export interface UserResponse {
  data: User[] | null;
  error: string | null;
}

// Create the API slice
export const usersService = createApi({
  reducerPath: "usersService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers) => {
      const accessToken = store.getState().accessToken;
      const currentUser = store.getState().currentUser as CurrentUser;

      headers.set("Content-Type", "application/json");

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      if (currentUser?.tenantSlug) {
        headers.set("Tenant-Slug", currentUser.tenantSlug);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUsers: builder.query<UserResponse, void>({
      query: () => ({
        url: "/users",
        method: "GET",
      }),
      transformResponse: (response: { data: User[] }): UserResponse => ({
        data: response.data,
        error: null,
      }),
      transformErrorResponse: (): UserResponse => ({
        data: null,
        error: "Fetching current user failed",
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetUsersQuery } = usersService;