import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Referral,
  Patient,
} from "./MediaPatient";
import store from "../redux/store";
import { CurrentUser } from "./User";

interface DataResponse {
  data: PatientWithMedias[] | null;
  error: string | null;
}

export interface PatientWithMedias extends Patient {
  referrals: Referral[];
}

export const patientsService = createApi({
  reducerPath: "patientsService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers) => {
      const accessToken = store.getState().accessToken;
      const currentUser = store.getState().currentUser as CurrentUser;

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      headers.set("Tenant-Slug", currentUser.tenantSlug);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPatientsByMobileNumber: builder.query<DataResponse, string | undefined>({
      query: (mobilePhoneNumber) => ({
        url: `/patients?mobilePhoneNumber=${mobilePhoneNumber}`,
      }),
    }),
  }),
});


export const { useGetPatientsByMobileNumberQuery } = patientsService;
