import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CurrentUser } from "./User";
import store from "../redux/store";

export interface SidebarStatistics {
  data: {
    unassignedReferralsCount: number;
    unassignedFormsCount: number;
  };
}

export interface ApiErrors {
  errors: string[];
}

export interface UnassignedStatisticsParams {
  locationIds?: string[];
  updatedAt?: string;
}

export const statisticsService = createApi({
  reducerPath: "statisticsService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers) => {
      const accessToken = store.getState().accessToken;
      const currentUser = store.getState().currentUser as CurrentUser;
      headers.set("Content-Type", "application/json");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      if (currentUser?.tenantSlug) {
        headers.set("Tenant-Slug", currentUser.tenantSlug);
      }
      return headers;
    },
  }),
  tagTypes: ["SidebarStatistics"],
  endpoints: (builder) => ({
    getSidebarStatistics: builder.query<SidebarStatistics, UnassignedStatisticsParams>({
      query: (params) => {
        const { locationIds, updatedAt } = params;
        let url = "/statistics/unassigned";
        const queryParams = [];
        
        if (locationIds && locationIds.length > 0) {
          queryParams.push(`locationIds=${locationIds.join(",")}`);
        }
        
        if (updatedAt) {
          queryParams.push(`updatedAt=${encodeURIComponent(updatedAt)}`);
        }
        
        if (queryParams.length > 0) {
          url = `${url}?${queryParams.join("&")}`;
        }
        
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["SidebarStatistics"],
    }),
  }),
});

export const { useGetSidebarStatisticsQuery } = statisticsService;
