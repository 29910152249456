import React, { useRef} from "react";
import { SurveyQuestionElementBase } from "survey-react-ui";

export interface Point {
  id: string;
  x: number;
  y: number;
}

export const StandaloneImagePicker: React.FC<{
  imageSrc: string;
  points: Point[];
  onChange?: (points: Point[]) => void;
  readOnly?: boolean;
}> = ({ 
  imageSrc = "/human_image.png", 
  points = [],
  onChange,
  readOnly = false
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
    if (readOnly) return;
    if (!imageRef.current || !containerRef.current) return;
    
    const rect = imageRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    
    const percentX = (offsetX / rect.width) * 100;
    const percentY = (offsetY / rect.height) * 100;
    
    const newPoint: Point = {
      id: `point-${Date.now()}`,
      x: percentX,
      y: percentY
    };
    
    const updatedPoints = [...points, newPoint];
    
    if (onChange) {
      onChange(updatedPoints);
    }
  };
  
  const handlePointClick = (e: React.MouseEvent, pointId: string) => {
    if (readOnly) return;
    e.stopPropagation();
    
    const updatedPoints = points.filter(p => p.id !== pointId);
    
    if (onChange) {
      onChange(updatedPoints);
    }
  };
  
  const handleClearAll = () => {
    if (readOnly) return;
    
    if (onChange) {
      onChange([]);
    }
  };
  
  return (
    <div className="image-picker">
      <div 
        ref={containerRef}
        style={{ position: "relative", maxWidth: "100%", display: "inline-block" }}
      >
        <img 
          ref={imageRef}
          src={imageSrc} 
          alt="Click to mark points" 
          onClick={handleImageClick}
          style={{ 
            maxWidth: "100%", 
            height: "auto", 
            cursor: readOnly ? "default" : "crosshair" 
          }}
        />
        
        {points.map(point => (
          <div 
            key={point.id}
            onClick={(e) => handlePointClick(e, point.id)}
            style={{
              position: "absolute",
              left: `${point.x}%`,
              top: `${point.y}%`,
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "rgba(255, 0, 0, 0.6)",
              border: "2px solid #ff0000",
              transform: "translate(-50%, -50%)",
              cursor: readOnly ? "default" : "pointer",
              zIndex: 10
            }}
            title={readOnly ? "" : "Click to remove this point"}
          />
        ))}
      </div>
      
      {!readOnly && points.length > 0 && (
        <div style={{ marginTop: "10px" }}>
          <button 
            onClick={handleClearAll}
            style={{
              padding: "5px 10px",
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer"
            }}
          >
            Clear All Points
          </button>
          <p style={{ marginTop: "5px", fontSize: "14px", color: "#666" }}>
            {points.length} {points.length === 1 ? "point" : "points"} marked
          </p>
        </div>
      )}
    </div>
  );
};

export class ImagePicker extends SurveyQuestionElementBase {
  private localPoints: Point[] = [];
  
  constructor(props: any) {
    super(props);
    this.handlePointsChange = this.handlePointsChange.bind(this);
    
    try {
      if (props.question && props.question.value) {
        this.localPoints = props.question.value;
      }
    } catch (e) {
      console.error("Error getting initial value:", e);
    }
  }
  
  get question() {
    return this.questionBase;
  }
  
  get imageSrc() {
    try {
      return this.question.getPropertyValue("imageSrc", "/human_image.png");
    } catch (e) {
      console.error("Error getting imageSrc:", e);
      return "/human_image.png";
    }
  }
  
  get isReadOnly() {
    try {
      return this.question.isReadOnly || this.question.isDesignMode;
    } catch (e) {
      return false;
    }
  }
  
  handlePointsChange(points: Point[]) {
    console.log("Points changed:", points);
    
    this.localPoints = points;
    
    try {
      if (this.question) {
        this.question.value = points;
        console.log("Value set on question:", this.question.value);
      }
    } catch (e) {
      console.error("Error setting value on question:", e);
    }
    
    this.forceUpdate();
  }
  
  get currentValue(): Point[] {
    try {
      if (this.question && this.question.value !== undefined) {
        return this.question.value;
      }
    } catch (e) {
      console.error("Error getting value from question:", e);
    }
    
    return this.localPoints;
  }
  
  // Render the component
  renderElement() {
    return (
      <StandaloneImagePicker 
        imageSrc={this.imageSrc}
        points={this.currentValue}
        onChange={this.handlePointsChange}
        readOnly={this.isReadOnly}
      />
    );
  }
  
  // Component will unmount - cleanup
  componentWillUnmount() {
    this.localPoints = [];
  }
}

export default ImagePicker;
