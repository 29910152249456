import React, { ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { RouterProvider } from "react-router-dom";
import { routes } from "./Routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./redux/store";

interface Auth0ProviderProps {
  children: ReactNode;
  domain: string;
  clientId: string;
  authorizationParams: {
    redirect_uri: string;
  };
}

const Auth0ProviderWithRedirectCallback: React.FC<Auth0ProviderProps> = ({
  children,
  ...props
}) => {
  const onRedirectCallback = (appState: any) => {
    const returnTo = appState?.returnTo || window.location.pathname;
    console.log("onRedirectCallback", returnTo);
    window.location.replace(returnTo); // Use replace to prevent history stack issues
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const persistor = persistStore(store);

export default function App() {
  return (
    <Auth0ProviderWithRedirectCallback
      domain={process.env.REACT_APP_AUTH_0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH_0_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH_0_REDIRECT_URI || "",
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={routes} />
        </PersistGate>
      </Provider>
    </Auth0ProviderWithRedirectCallback>
  );
}
