import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { SurveyCreatorComponent } from "survey-creator-react";
import React, { useEffect, useState } from "react";
import defaultSurvey from "./default_survey.json";
import { 
  useCreateFormMutation, 
  useUpdateFormMutation, 
  useGetFormQuery 
} from "../../services/forms/FormService";
import { useNavigate, useParams } from "react-router-dom";
import { 
  useSurveyInitialization,
  createSurveyCreator,
  addCustomComponentsToToolbox,
  safeJsonParse
} from "./utils/survey-utils";

const FormCreator: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isEditMode = !!id;
  
  const { data: formData, isLoading: isLoadingForm } = useGetFormQuery(id || "", { 
    skip: !isEditMode 
  });
  
  const [createForm] = useCreateFormMutation();
  const [updateForm] = useUpdateFormMutation();
  
  useSurveyInitialization();
  
  const initialSurveyJSON = defaultSurvey;
  
  const [creator] = useState(() => {
    const newCreator = createSurveyCreator();
    return newCreator;
  });
  
  useEffect(() => {
    if (isEditMode && formData?.data) {
      try {
        if (formData.data.questionsData) {
          const parsedData = safeJsonParse(formData.data.questionsData, initialSurveyJSON);
          creator.text = JSON.stringify(parsedData);
        } 
      } catch (error) {
        creator.text = JSON.stringify(initialSurveyJSON);
      }
    } else if (!isEditMode) {
      creator.text = JSON.stringify(initialSurveyJSON);
    }
    
    creator.saveSurveyFunc = async () => {
      // Add extra logging to debug what's happening with the form data
      if (creator.survey) {
        console.log("Survey before save:", creator.survey);
        console.log("Survey data:", creator.survey.data);
        
        // Check if there are any image picker questions and their values
        creator.survey.getAllQuestions().forEach(q => {
          if (q.getType() === "imagepicker") {
            console.log(`ImagePicker question '${q.name}' value:`, q.value);
          }
        });
      }
      
      const questionsData = JSON.parse(creator.text);
      console.log("Questions data being saved:", questionsData);

      if (isEditMode && id) {
        await updateForm({
          id,
          body: {
            formType: { name: creator.survey?.title },
            questionsData
          }
        }).unwrap();
      } else {
        const response = await createForm({
          formType: { name: creator.survey?.title },
          questionsData
        }).unwrap();
        localStorage.removeItem("savedSurveyJSON");
        window.location.href = `/forms/${response.data?.id}/edit`;
      }
    };

    creator.onTestSurveyCreated.add((sender: any, options: any) => {
      const survey = options.survey;
      console.log("Test survey created:", survey);
      
      // Only add event handlers if the corresponding events exist
      if (survey.onValueChanged) {
        // Add value changed callback to track changes in real-time
        survey.onValueChanged.add((sender: any, options: any) => {
          console.log("Value changed in test survey:", options.name, options.value);
          console.log("Current survey data:", sender.data);
        });
      }

      // Check if onQuestionValueChanged exists before subscribing
      if (survey.onQuestionValueChanged) {
        // Track when a question's value changes
        survey.onQuestionValueChanged.add((sender: any, options: any) => {
          console.log(
            "Question value changed:", 
            options.name, 
            options.question?.getType && options.question.getType(), 
            options.value
          );
          
          // Special handling for imagepicker questions
          if (options.question?.getType && options.question.getType() === "imagepicker") {
            console.log("ImagePicker value changed:", options.value);
          }
        });
      }

      // Check if onComplete exists before subscribing
      if (survey.onComplete) {
        survey.onComplete.add((sender: any) => {
          const results = sender.data;
          console.log("Form completed in preview mode. Answers:", results);
          
          // Check for imagepicker questions specifically
          if (survey.getAllQuestions) {
            try {
              survey.getAllQuestions().forEach((q: any) => {
                if (q.getType && q.getType() === "imagepicker") {
                  console.log(`Final ImagePicker '${q.name}' value:`, q.value);
                }
              });
            } catch (e) {
              console.error("Error checking questions:", e);
            }
          }
        });
      }
    });
  }, [isEditMode, formData, id, createForm, updateForm, navigate]);

  
  useEffect(() => {
    addCustomComponentsToToolbox(creator);
  }, [creator]);

  if (isEditMode && isLoadingForm) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }
  
  return (
    <div className="w-full mx-auto pb-4">
      <div className="h-[calc(100vh-65px)]">
        <SurveyCreatorComponent creator={creator} />
      </div>
    </div>
  );
};

export default FormCreator;