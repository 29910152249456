import "survey-core/defaultV2.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import React, { useEffect, useState } from "react";
import defaultSurvey from "./default_survey.json";
import { 
  useSurveyInitialization, 
} from "./utils/survey-utils";
import { 
  useGetPublicPatientFormRequestQuery, 
  useSubmitFormAnswersMutation 
} from "../../services/forms/PatientFormRequestService";
import { useParams } from "react-router-dom";

const FormViewer: React.FC = () => {
  const [survey, setSurvey] = useState<Model | null>(null);
  const { id } = useParams<{ id: string }>();
  const { slug } = useParams<{ slug: string }>();
  
  const { createSurveyModel, safeJsonParse } = useSurveyInitialization();
  
  
  const { data: patientFormRequestData, isLoading, error } = useGetPublicPatientFormRequestQuery(
    { tenantId: slug, publicId: id || "" },
    { skip: !id || !slug }
  );
  
  // RTK Query mutation hook for submitting form answers
  const [submitFormAnswers] = useSubmitFormAnswersMutation();
  
  useEffect(() => {
    if (id && patientFormRequestData?.data?.form) {
      try {
        const form = patientFormRequestData.data.form;

        if (form.questionsData) {
          const formStructure = safeJsonParse(form.questionsData, defaultSurvey);
          
          
          const surveyModel = createSurveyModel(formStructure, {
            showNavigationButtons: true,
            showCompletedPage: true
          });
          
          surveyModel.data = form.questionsData;
          
          surveyModel.onComplete.add(async (sender) => {
            console.log("Survey completed", sender.data);
            
            if (!id) {
              console.error("Missing patient form request ID");
              return;
            }
            
            try {
              // Submit the form data
              await submitFormAnswers({
                patientFormRequestId: id,
                questionAnswers: sender.data,
                tenantId: slug

              });
              
              console.log("Form answers submitted successfully");
            } catch (err) {
              console.error("Error submitting form answers:", err);
            }
          });
          
          setSurvey(surveyModel);
          return;
        }
      } catch (err) {
        console.error("Error processing form data:", err);
      }
    }
  }, [id, patientFormRequestData, isLoading, error, createSurveyModel, safeJsonParse]);

  // Show loading spinner when loading data
  if (isLoading || (!survey && id)) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }
  
  // Show error message if there was an error loading the form
  if (error && id) {
    return (
      <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
        Error loading form
      </div>
    );
  }
  
  // Ensure survey is loaded
  if (!survey) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }

  return (
    <Survey model={survey} />
  );
};

export default FormViewer;