import "survey-core/defaultV2.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import React, { useEffect, useState } from "react";
import { useSurveyInitialization } from "./utils/survey-utils";
import { useParams } from "react-router-dom";
import { useGetFormAnswerQuery } from "../../services/forms/FormAnswersService";

const FormResponse: React.FC = () => {
  console.log("FormResponse component rendered");
  const { id } = useParams<{ id: string }>();
  const [survey, setSurvey] = useState<Model | null>(null);
  const { createSurveyModel, safeJsonParse } = useSurveyInitialization();
  
  // Fetch the form answer data using the query hook
  const { 
    data: formAnswerResponse, 
    isLoading, 
    error 
  } = useGetFormAnswerQuery(id || "", {
    skip: !id
  });

  useEffect(() => {
    if (id && formAnswerResponse?.data) {
      try {
        const formAnswer = formAnswerResponse.data;
        
        // Get the form data from the answer
        if (formAnswer.form && formAnswer.answersData) {
          // Create a survey model in display mode
          const surveyModel = createSurveyModel(
            safeJsonParse(formAnswer.answersData),
            {
              mode: "display",
              showNavigationButtons: true,
              showCompletedPage: false
            }
          );
          
          // Set the data from the form answer
          surveyModel.data = formAnswer.answersData;
          
          setSurvey(surveyModel);
        }
      } catch (err) {
        console.error("Error processing form answer data:", err);
      }
    }
  }, [id, formAnswerResponse, isLoading, createSurveyModel, safeJsonParse]);

  // Show loading spinner when loading data
  if (isLoading || (!survey && id)) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }
  
  // Show error message if there was an error loading the form
  if (error && id) {
    return (
      <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
        Error loading form response
      </div>
    );
  }
  
  // Ensure survey is loaded
  if (!survey) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }

  return (
    <Survey model={survey} />
  );
};

export default FormResponse;