import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import store from "../../redux/store";
import { CurrentUser } from "../User";
import { FormType } from "./FormAnswersService";

export enum ServiceProvider {
  ELVATI_FORMS = "ELVATI_FORMS",
  SNAP_FORMS = "SNAP_FORMS",
}

export interface Form {
  id: string
  tenantId: string
  formType: FormType,
  externalUrl: string
  externalFormId: string
  serviceProvider: ServiceProvider,
  questionsData?: Record<string, unknown> | null
  createdAt: string
  updatedAt: string
}

interface FormsResponseApiResponse {
  data: Form[],
  metadata: {
    totalCount: number
  }
}

interface FormResponse {
  data?: Form | null;
  errors?: string[];
}

interface FormsRequestParams {
  itemsPerPage?: number
  itemOffset?: number
  query: string | null
  locationIds: string[] | null
}

export interface CreateFormRequest {
  formType: {
    name: string;
  };
  questionsData: Record<string, unknown>;
}

export const forms = createApi({
  reducerPath: "formsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers) => {
      const accessToken = store.getState().accessToken;
      const currentUser = store.getState().currentUser as CurrentUser;

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      headers.set("Tenant-Slug", currentUser.tenantSlug);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getForms: builder.query<FormsResponseApiResponse, FormsRequestParams>({
      query: ({
        itemsPerPage = 10,
        itemOffset = 0,
        query,
        locationIds
      }) => {
        let apiUrl = `/forms?itemsPerPage=${itemsPerPage}&itemOffset=${itemOffset}`;

        if (query) {
          apiUrl += `&query=${query}`;
        }

        if (locationIds) {
          apiUrl += `&locationIds=${locationIds.join(",")}`;
        }

        return {
          url: apiUrl,
          method: "GET",
        };
      },
      keepUnusedDataFor: 3,
    }),
    getForm: builder.query<FormResponse, string>({
      query: (id) => ({
        url: `/forms/${id}`,
        method: "GET",
      }),
    }),
    createForm: builder.mutation<FormResponse, CreateFormRequest>({
      query: (body) => ({
        url: "/forms",
        method: "POST",
        body,
      }),
    }),
    updateForm: builder.mutation<FormResponse, { id: string; body: CreateFormRequest }>({
      query: ({ id, body }) => ({
        url: `/forms/${id}`,
        method: "PATCH",
        body,
      }),
    })
  }),
});

export const { 
  useGetFormsQuery, 
  useGetFormQuery, 
  useCreateFormMutation, 
  useUpdateFormMutation 
} = forms;
